import { BaseListFilterType, initBaseListFilterType, RangeDateType } from "../../../../../shared/types";

/**
 * Default filter
 */
const default_filter = {
  concreting_date: null,
  test_age: {
    min: null,
    max: null,
  },
  os_number: {
    min: null,
    max: null,
  },
  nfe_number: {
    min: null,
    max: null,
  },
  code_number: {
    min: null,
    max: null,
  },
  pending: null,
  molded: null,
  unmolded: null,
};

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} TestListFilterExtType
 * @property {Array} global - Usado para buscas no padrão antigo da API
 * @property {Array<null|true|false>} molding - [null, true, false] Todos, Com moldagem, Sem moldagem
 * @property {RangeDateType|null} range - Intervalo entre datas
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType)} TestListFilterType
 */
export const TestListFilterType = {};

/**
 * Inicializar TestListFilterType
 */
export const initTestListFilterType = () => ({
  ...initBaseListFilterType(),
  ...default_filter,
  global: [],
  molding: null, // Inicializado com 'Todos'
  ignore_molding: null, // para ignorar o filtro de moldagem no getWidgets, para não afetar a contagem total
  range: {
    start: null,
    end: null,
  },
});
